import request, { applicationJson } from "@/utils/axios";

export function downloadExcel(params) {
  return applicationJson.post("/api/tool/conversion/result", params, {
    responseType: "blob",
  });
}

export function pTom(params) {
  return request.get("/api/tool/conversion/mkolids", params);
}
