<template>
  <div class="title_contain">
    <i :class="[isSecondColor ? 'secondColor' : '', 'line']"></i>
    <div class="content">
      <div class="title_content">
        <span class="title_text">{{ title }}</span>
        <slot name="left" />
      </div>
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: "mainTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    isSecondColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.title_contain {
  background-color: #ffffff;
  color: $color-text;
  line-height: 40px;
  height: 40px;
  border-radius: 5px;
  box-shadow: 1px 6px 4px #ccc;
  display: flex;
  align-items: center;

  .line {
    width: 5px;
    height: 25px;
    background-color: #51a6f0;
    border-radius: 4px;
    margin: 0 8px;
  }

  .secondColor {
    background-color: rgb(241, 194, 50);
  }

  .content {
    flex: 1;
    display: flex;
    align-items: center;
    padding-right: 20px;

    .title_content {
      flex: 1;
      display: flex;
      align-items: center;

      .title_text {
        font-weight: 800;
        font-size: 16px;
        margin-right: 20px;
      }
    }
  }
}
</style>
