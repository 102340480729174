<template>
  <div class="toolsWrap">
    <el-tabs type="border-card" v-model="tabActive">
      <el-tab-pane name="first" label="文件转换">
        <div style="text-align: center">
          <el-upload
            drag
            ref="upload"
            :action="addKolaction"
            :file-list="kolFileList"
            :headers="{ token }"
            :accept="accept"
            :limit="limit"
            :before-upload="fileBeforeUpload"
            :on-success="fileOnSuccess"
            :on-error="fileOnError"
            :on-remove="fileRemove"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <template #tip>
              <div class="el-upload__tip">
                <span style="color: red; margintop: 5px">
                  * 只能上传xls, xlsx文件，且不超过30M
                </span>
              </div>
            </template>
          </el-upload>
        </div>
        <el-table
          size="mini"
          :data="tableData"
          border
          highlight-current-row
          style="margin-top: 20px"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column
            align="center"
            v-for="(item, index) of tableHeader"
            :prop="item"
            :label="fromCharCode(index)"
            :key="item"
          >
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
          <main-title title="表头定制" />
          <div style="margin-top: 20px">
            <el-form
              ref="defineTableHeader"
              :rules="rules"
              :model="defineForm"
              label-width="140px"
            >
              <el-row>
                <el-col :span="8">
                  <el-form-item label="平台对应字段" prop="platform">
                    <el-select
                      v-model="defineForm.platform"
                      placeholder="请选择平台对应字段"
                    >
                      <el-option
                        v-for="item in platFormFieldOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="m_kol_id 所在列" prop="mKolId">
                    <el-input
                      v-model="defineForm.mKolId"
                      placeholder="请输入m_kol_id 所在列,如'A'、'AB'"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="p_kol_id 对应字段">
                微信:
                <el-select
                  v-model="defineForm.pKolId.wechat"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in platFormFieldOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                微博:
                <el-select
                  v-model="defineForm.pKolId.weibo"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in platFormFieldOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                抖音:
                <el-select
                  v-model="defineForm.pKolId.douyin"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in platFormFieldOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                小红书:
                <el-select
                  v-model="defineForm.pKolId.xiaohongshu"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in platFormFieldOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                哔哩哔哩:
                <el-select
                  v-model="defineForm.pKolId.bilibili"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in platFormFieldOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  :loading="createTableLoading"
                  @click="createTable"
                  >生成表格
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="second" label="单个转换">
        <div class="container">
          <el-form
            :model="sigleForm"
            :rules="sigleRules"
            ref="sigleForm"
            label-width="80px"
          >
            <el-form-item label="p_kolId" prop="sigleArea" class="pkolidClass">
              <el-input
                type="textarea"
                placeholder="p_kolId之间以逗号隔开，不能多于20个"
                v-model="sigleForm.sigleArea"
              ></el-input>
            </el-form-item>
            <el-form-item label="平台" prop="siglePlatform">
              <el-select
                v-model="sigleForm.siglePlatform"
                placeholder="请选择平台"
              >
                <el-option label="微信" value="wechat"></el-option>
                <el-option label="微博" value="weibo"></el-option>
                <el-option label="抖音" value="douyin"></el-option>
                <el-option label="小红书" value="xiaohongshu"></el-option>
                <el-option label="哔哩哔哩" value="bilibili"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div style="margin-top: 40px">
            <el-button
              type="primary"
              :loading="conversionLoading"
              @click="submitConversion('sigleForm')"
              >转换
            </el-button>
          </div>
          <el-table :data="sigleTableData" style="width: 600px">
            <el-table-column
              width="250"
              align="center"
              prop="pid"
              label="p_kolId"
            >
            </el-table-column>
            <el-table-column align="center" prop="mid" label="m_kolId">
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { downloadExcel, pTom } from "@/api/mkolidConversion";
import mainTitle from "@/components/common/mainTitle";

export default {
  data() {
    const checkMkolid = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("m_kol_id 所在列不能为空"));
      }
      let regular = new RegExp("^[a-zA-Z]{1,2}$");
      console.log(regular.test(value));
      setTimeout(() => {
        if (!regular.test(value)) {
          callback(new Error('填写一个或两个大写字母，如"A"、"AB"'));
        } else {
          callback();
        }
      }, 200);
    };
    const checkPkolid = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("p_kol_id 所在列不能为空"));
      }
      console.log(value.split(",").length);
      if (value.split(",").length > 20) {
        return callback(new Error("p_kolId 不能多于20个"));
      }
      let regular = new RegExp("^[^,]+(,[^,]+)*$");
      console.log(regular.test(value));
      setTimeout(() => {
        if (!regular.test(value)) {
          callback(new Error("p_kol_id 以逗号分割"));
        } else {
          callback();
        }
      }, 200);
    };
    return {
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#f3f4f4",
        borderTopStyle: "solid",
        fontWeight: "blod",
      },
      cellStyle: {
        fontSize: "12px",
      },
      tabActive: "first",
      addKolaction: process.env.VUE_APP_API_HOST + "/api/tool/conversion/excel",
      kolFileList: [],
      accept: ".xls,.xlsx",
      limit: 1,
      tableData: [],
      tableHeader: [],
      defineForm: {
        platform: "",
        pKolId: {
          wechat: "",
          weibo: "",
          douyin: "",
          xiaohongshu: "",
          bilibili: "",
        },
        mKolId: "",
      },
      rules: {
        platform: [
          { required: true, message: "平台对应字段不能为空", trigger: "blur" },
        ],
        mKolId: [{ validator: checkMkolid, trigger: "blur" }],
      },
      platFormFieldOptions: [],
      createTableLoading: false,
      sigleForm: {
        sigleArea: "",
        siglePlatform: "",
      },
      sigleRules: {
        siglePlatform: [
          {
            required: true,
            message: "平台对应字段不能为空",
            trigger: "change",
          },
        ],
        sigleArea: [{ validator: checkPkolid, trigger: "blur" }],
      },
      sigleTableData: [],
      path: "",
      excelName: "",
      conversionLoading: false,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    beforeUpload(file) {
      console.log(file);
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    fileBeforeUpload(file) {
      const sizeLimit = 1024 * 1024 * 30;
      if (file.size > sizeLimit) {
        this.$message({
          message: "文件不能大于30M",
          type: "warning",
        });
        return false;
      } else {
        return true;
      }
    },
    fileOnSuccess(res, file) {
      if (res.code === 0) {
        this.excelName = file.name.split(".")[0];
        this.path = res.data.path;
        this.tableHeader = res.data.headers;
        this.platFormFieldOptions = this.formatTableHeaders(res.data.headers);
        this.tableData = this.formatTableData(res.data);
        setTimeout(() => {
          this.judgement(res.data.headers);
        }, 100);
      } else {
        this.fileOnError();
      }
    },
    fileOnError() {
      this.$refs.upload.clearFiles();
      this.$message.error("上传文件失败!");
    },
    fileRemove() {
      this.tableData = [];
      this.$nextTick(() => {
        this.$refs.defineTableHeader.resetFields();
        this.defineForm.pKolId = {
          wechat: "",
          weibo: "",
          douyin: "",
          xiaohongshu: "",
          bilibili: "",
        };
      });
    },
    formatTableData(data) {
      data.excelData.unshift(data.headers);
      let tempRes = [];
      data.excelData.forEach((k) => {
        let tempObj = {};
        data.headers.forEach((v, j) => {
          tempObj[v] = k[j];
        });
        tempRes.push(tempObj);
      });
      return tempRes;
    },
    formatTableHeaders(data) {
      let resArr = [];
      data.forEach((v, i) => {
        let tempObj = {};
        tempObj.value = i;
        tempObj.label = v;
        resArr.push(tempObj);
      });
      return resArr;
    },
    judgement(data) {
      if (data.includes("biz")) {
        this.defineForm.pKolId.wechat = data.indexOf("biz");
      }
      if (data.includes("p_kolid")) {
        this.defineForm.pKolId.weibo = data.indexOf("p_kolid");
        this.defineForm.pKolId.xiaohongshu = data.indexOf("p_kolid");
        this.defineForm.pKolId.bilibili = data.indexOf("p_kolid");
      }
      if (data.includes("p_kolid2")) {
        this.defineForm.pKolId.douyin = data.indexOf("p_kolid2");
      }
      if (data.includes("platform")) {
        this.defineForm.platform = data.indexOf("platform");
      }
      this.defineForm.mKolId = String.fromCharCode(data.length + 65);
    },
    fromCharCode(num) {
      if (num < 26) {
        return String.fromCharCode(num + 65);
      } else {
        return String.fromCharCode(num / 26 + 64, (num % 26) + 65);
      }
    },
    formatMkolId(data) {
      console.log(data);
      if (data.length === 1) {
        return data.charCodeAt() - 65;
      } else {
        return (data[0].charCodeAt() - 64) * 26 + data[1].charCodeAt() - 65;
      }
    },
    createTable() {
      this.$refs.defineTableHeader.validate(async (valid) => {
        if (valid) {
          this.createTableLoading = true;
          this.$message({
            message: "正在处理，请耐心等待!",
            type: "info",
          });
          let params = {
            filepath: this.path,
            platform: this.defineForm.platform,
            pkolid: {
              wechat:
                this.defineForm.pKolId.wechat === ""
                  ? -1
                  : this.defineForm.pKolId.wechat,
              weibo:
                this.defineForm.pKolId.weibo === ""
                  ? -1
                  : this.defineForm.pKolId.weibo,
              douyin:
                this.defineForm.pKolId.douyin === ""
                  ? -1
                  : this.defineForm.pKolId.douyin,
              xiaohongshu:
                this.defineForm.pKolId.xiaohongshu === ""
                  ? -1
                  : this.defineForm.pKolId.xiaohongshu,
              bilibili:
                this.defineForm.pKolId.bilibili === ""
                  ? -1
                  : this.defineForm.pKolId.bilibili,
            },
            mkolid: this.formatMkolId(this.defineForm.mKolId.toUpperCase()),
          };
          let res = await downloadExcel(params);
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.excelName}_result.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.$message({
            message: "处理成功，正在下载",
            type: "success",
          });
          this.createTableLoading = false;
          this.$nextTick(() => {
            this.$refs.defineTableHeader.resetFields();
            this.defineForm.pKolId = {
              wechat: "",
              weibo: "",
              douyin: "",
              xiaohongshu: "",
              bilibili: "",
            };
          });
        } else {
          return false;
        }
      });
    },
    async submitConversion(form) {
      this.$refs[form].validate(async (valid) => {
        this.sigleTableData = [];
        if (valid) {
          this.conversionLoading = true;
          let res = await pTom({
            pkolids: this.sigleForm.sigleArea,
            platform: this.sigleForm.siglePlatform,
          });
          this.sigleForm.sigleArea.split(",").forEach((v, i) => {
            this.sigleTableData.push({
              pid: v,
              mid: res.data.mkolids[i],
            });
            console.log(this.sigleTableData);
          });
          this.conversionLoading = false;
        } else {
          return false;
        }
      });
    },
  },
  components: {
    mainTitle,
  },
};
</script>

<style lang="scss">
.toolsWrap {
  .el-input.el-input--suffix {
    width: 230px;
  }

  .el-select {
    margin-right: 20px;
  }
}
</style>

<style scoped lang="scss">
.toolsWrap {
  padding: 10px 5px;
  background-color: #fff;
  .container {
    min-height: 400px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
